<template>
  <div>
    <titulo-relatorio-turmas-aluno />
    <b-row>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="anoLetivoId"
          v-model="selecionados.anoLetivoId"
          :label="$t('ACADEMICO.ANO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="cursoId"
          v-model="selecionados.cursoId"
          :label="$t('ACADEMICO.CURSO')"
          :options="opcoes.cursos"
          :disabled="desabilitar.curso"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="tipoContrado"
          v-model="selecionados.tipoContrato"
          :label="$t('ACADEMICO.TIPO_CONTRATO')"
          :options="opcoes.tipoContratos"
        />
      </b-col>

      <b-col cols="12" md="auto" class="mt-3">
        <b-button
          class="botao-acao-filtro w-100 my-3"
          variant="info"
          @click="realizarImpressao"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import { InputSelectMultiple } from '@/components/inputs';
import TituloRelatorioTurmasAluno from '@/views/academico/relatorios/relatorio-turmas-aluno/TituloRelatorioTurmasAluno';

export default {
  components: {
    InputSelectMultiple,
    TituloRelatorioTurmasAluno,
  },
  data() {
    return {
      selecionados: {
        anoLetivoId: null,
        cursoId: null,
        tipoContrato: null,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        tipoContratos: [],
      },
      desabilitar: {
        curso: true,
      },
    };
  },
  mounted() {
    this.getAnosLetivos();
    this.getTipoContratos();
  },
  watch: {
    'selecionados.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getCursos(anoLetivoId.id);
      } else {
        this.opcoes.cursos = [];
        this.desabilitar.curso = true;
      }
      this.selecionados.cursoId = null;
    },
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    realizarImpressao() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);

      const { anoLetivoId, cursoId, tipoContrato } = this.selecionados;

      const objetoParaFiltro = {
        anoLetivoId: anoLetivoId.id,
        cursoId: cursoId?.id,
        tipoContrato: tipoContrato?.id,
      };

      TurmaService.realizarImpressaoRelatorioTurmasParaAluno(objetoParaFiltro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contratos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.ajuste-fino-btn {
  margin-top: 28px;
}
</style>
